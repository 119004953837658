import type {
  Item,
  Menu,
  MenuTpa,
  Section,
  SectionTpa,
  PopulatedMenu,
  PopulatedSection,
} from '../../types/menusTypes';
import type { MenusAvailabilityStatus, Money, TimeSlotsPerMenu } from '../../types/businessTypes';
import type { PopulatedItemTpa } from 'root/types/item';
import {
  AvailabilityStatus,
  type MenuAvailabilityStatus,
} from '@wix/ambassador-restaurants-menu-settings-v1-menu-ordering-settings/types';
import type {
  FirstFulfillmentTimeSlotsPerMenu,
  FulfillmentTimeSlot,
} from '@wix/ambassador-restaurants-operations-v1-operation/build/cjs/types.impl';

export const convertPopulatedItemsToItemType = ({
  items,
  currency,
  imagesData = [],
}: {
  items: PopulatedItemTpa[];
  currency: string;
  imagesData?: { id: string; width: number; height: number }[];
}): Item[] => {
  const updatedItems: Item[] = [];
  items.forEach((item) => {
    const price: Money = {
      amount: Number(item.priceInfo?.price) || 0,
      currency,
    };

    if (item.image && item.image.id && !(item.image.width || item.image.height)) {
      const imgData = imagesData.find((img) => img.id === item.image?.id);

      item.image.width = item.image.width || imgData?.width;
      item.image.height = item.image.height || imgData?.height;
    }

    if (item.id) {
      updatedItems.push({
        id: item.id,
        name: item.name,
        description: item.description,
        price,
        priceVariants: item.priceVariants,
        labels: item.labels,
        image: item.image,
        additionalImages: item.additionalImages,
        visible: !!item.visible,
        orderSettings: {
          inStock: item.orderSettings?.inStock ?? true,
          taxGroupId: item.orderSettings?.taxGroupId,
          acceptSpecialRequests: item.orderSettings?.acceptSpecialRequests,
        },
        modifierGroups: item.modifierGroups,
        revision: item.revision || '0',
      });
    }
  });

  return updatedItems;
};

export const convertSectionsToSectionType = ({
  sections,
}: {
  sections: SectionTpa[];
}): Section[] => {
  const updatedSections: Section[] = [];
  sections.forEach((section) => {
    if (section.id) {
      updatedSections.push({
        id: section.id,
        name: section.name,
        description: section.description,
        image: section.image,
        additionalImages: section.additionalImages,
        visible: section.visible !== false,
        itemIds: section.itemIds || [],
        revision: section.revision || '0',
      });
    }
  });

  return updatedSections;
};

export const convertMenusToMenuType = ({ menus = [] }: { menus?: MenuTpa[] }): Menu[] => {
  const updatedMenus: Menu[] = [];
  menus.forEach((menu) => {
    if (menu.id) {
      updatedMenus.push({
        id: menu.id,
        name: menu.name,
        description: menu.description,
        visible: !!menu.visible,
        sectionIds: menu.sectionIds || [],
        revision: menu.revision || '0',
      });
    }
  });

  return updatedMenus;
};

export const convertDataTypes = async ({
  currency,
  menusTpa = [],
  sectionsTpa = [],
  itemsTpa = [],
  imagesData,
}: {
  currency: string;
  menusTpa?: MenuTpa[];
  sectionsTpa?: SectionTpa[];
  itemsTpa?: PopulatedItemTpa[];
  imagesData?: { id: string; width: number; height: number }[];
}) => {
  const menus = convertMenusToMenuType({
    menus: menusTpa,
  });

  const sections = convertSectionsToSectionType({
    sections: sectionsTpa,
  });

  const items = convertPopulatedItemsToItemType({
    items: itemsTpa as PopulatedItemTpa[],
    currency,
    imagesData,
  });

  return { menus, sections, items };
};

export const populateMenu = (menu: Menu, sections: Section[], items: Item[]): PopulatedMenu => {
  const itemMap = new Map();
  const sectionMap = new Map();

  items.forEach((item) => itemMap.set(item.id, item));
  sections.forEach((section) => sectionMap.set(section.id, section));

  const menuSections =
    menu.sectionIds
      ?.map((sectionId) => sectionMap.get(sectionId))
      ?.filter((section) => !!section) ?? [];

  const visibleMenuSections = menuSections.filter((section) => section.visible);

  let size = 0;

  const sectionsWithItems = visibleMenuSections.map((section) => {
    const sectionItems = section.itemIds
      ?.map((itemId: string) => itemMap.get(itemId))
      .filter((item: Item) => !!item);

    size += sectionItems.length;

    return {
      ...section,
      size: sectionItems.length,
      items: sectionItems,
    };
  }) as PopulatedSection[];

  return {
    ...menu,
    sections: sectionsWithItems,
    size,
  };
};

export const convertDate = (date: Date) => {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
};

export const createTimeSlotId = (startTime: Date, endTime: Date) =>
  `${startTime?.toUTCString()}-${endTime?.toUTCString()}`;

export const convertMenuAvailabilityStatusArrayToKeyValue = (
  menuStatuses: MenuAvailabilityStatus[] = []
): MenusAvailabilityStatus => {
  return menuStatuses.reduce((acc, status) => {
    if (status.menuId) {
      acc[status.menuId] = status.availabilityStatus ?? AvailabilityStatus.AVAILABLE;
    }
    return acc;
  }, {} as MenusAvailabilityStatus);
};

export const convertTimeSlotsPerMenuArrayToKeyValue = (
  timeSlotsPerMenu: FirstFulfillmentTimeSlotsPerMenu[] = []
) => {
  return timeSlotsPerMenu.reduce<TimeSlotsPerMenu>((menuAcc, menu) => {
    const menuId = menu.menuId ?? '';
    const timeSlotMap = (menu.timeslotsPerFulfillmentType ?? []).reduce<
      Record<string, FulfillmentTimeSlot>
    >((slotAcc, slot) => {
      const key = slot.fulfilmentType?.toLowerCase() ?? '';
      if (key) {
        slotAcc[key] = slot;
      }
      return slotAcc;
    }, {});
    if (menuId) {
      menuAcc[menuId] = timeSlotMap;
    }
    return menuAcc;
  }, {});
};
