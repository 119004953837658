import type { TFunction } from '@wix/yoshi-flow-editor';
import type {
  DisplayedLabel,
  DisplayedModifier,
} from '@wix/restaurants-populated-item-client/types';
import type { CatalogReferenceModifierGroup } from 'root/services/cartService';
import type { LabelWithSvg } from 'root/types/label';
import type {
  ModifierListItem,
  PopulatedModifierGroup,
  RepeaterModifierGroupListItem,
  SelectedModifiers,
} from 'root/types/modifiers';
import { v4 as uuidv4 } from 'uuid';

export const getModifierGroupsOptions = (
  modifierGroups: RepeaterModifierGroupListItem[],
  selectedModifiers: SelectedModifiers,
  formatCurrency: (price?: number) => string
) => {
  const modifierGroupsOptions = Object.keys(selectedModifiers)
    ?.map((modifierGroupId) => {
      const selectedModifierGroup = modifierGroups.find(
        (modifierGroup) => modifierGroup.id === modifierGroupId
      );
      return {
        id: selectedModifierGroup?.modifierGroupId ?? '',
        modifiers: selectedModifiers[modifierGroupId]?.map((modifier) => {
          const price = Number(modifier?.additionalChargeInfo?.additionalCharge);
          return {
            id: modifier.modifierId,
            price: modifier?.additionalChargeInfo?.additionalCharge,
            formattedPrice: price > 0 ? formatCurrency(price) : undefined,
          };
        }),
      };
    })
    .filter((modifierGroup) => Boolean(modifierGroup.modifiers.length && modifierGroup.id !== ''));
  return modifierGroupsOptions.length > 0
    ? (modifierGroupsOptions as CatalogReferenceModifierGroup[])
    : undefined;
};

export const getModifierListItem = (modifiers: ModifierListItem[], value: string) => {
  return modifiers.find((modifier) => value === modifier.id);
};

export const getFormattedModifierLabel = (
  name: string,
  price: string,
  t: TFunction,
  isAdditionalCharge?: boolean,
  inStock?: ModifierListItem['inStock']
) => {
  const stockLabel = inStock ? '' : `(${t('itemModal.OutOfStock')})`;

  if (isAdditionalCharge) {
    const priceLabel = inStock
      ? `(+${price})`
      : `(${t('itemModal.OutOfStock.modifierPrice', { price })})`;
    return `${name} ${priceLabel}`;
  }

  return `${name} ${stockLabel}`;
};

export const getModifiersOptions = (
  modifiers: ModifierListItem[],
  formatCurrency: (price?: number) => string,
  t: TFunction
) => {
  return modifiers.map((modifier) => {
    const isAdditionalCharge = Number(modifier.additionalChargeInfo?.additionalCharge) > 0;
    const formattedPrice = formatCurrency(Number(modifier.additionalChargeInfo?.additionalCharge));
    const label = getFormattedModifierLabel(
      modifier.name ?? '',
      formattedPrice,
      t,
      isAdditionalCharge,
      modifier.inStock
    );
    return {
      label,
      value: modifier.id,
    };
  });
};

const convertToRepeaterModifiers = (modifiers?: DisplayedModifier[]): ModifierListItem[] => {
  return (modifiers || []).map((modifier, index) => ({
    ...modifier,
    id: `${modifier.id}-${index}`,
    modifierId: modifier.id,
  }));
};

export const convertToRepeaterModifierGroups = (
  entities: PopulatedModifierGroup[]
): RepeaterModifierGroupListItem[] => {
  return entities.map((entity) => ({
    ...entity,
    id: uuidv4(),
    modifierGroupId: entity.id,
    modifiers: convertToRepeaterModifiers(entity.modifiers),
  }));
};

export const getLabelsWithSvgElement = async (
  labels?: DisplayedLabel[]
): Promise<LabelWithSvg[]> => {
  return Promise.all(
    labels?.map(async (label) => {
      const labelWithSvg = { ...label, _id: label.id } as LabelWithSvg;
      const svgUrl = label.icon?.url;
      if (svgUrl) {
        try {
          const svgResponse = await fetch?.(svgUrl);
          const svgElement = await svgResponse?.text();
          if (svgElement) {
            return { ...labelWithSvg, svgId: svgElement };
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('Error fetching svg:', error);
        }
      }
      return labelWithSvg;
    }) || []
  );
};

export const getAreModifiersofMandatoryModifierGroupOutOfStock = (
  modifierGroups: RepeaterModifierGroupListItem[] | PopulatedModifierGroup[]
) =>
  modifierGroups.some(
    (modifierGroup) =>
      modifierGroup.rule?.required && modifierGroup.modifiers?.some((modifier) => !modifier.inStock)
  );
