import type { Experiments, IHttpClient } from '@wix/yoshi-flow-editor';
import { SPECS } from 'root/appConsts/experiments';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import { OrdersSettingsClient } from './ordersSettingsClient';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import type { ReportError } from './utils/getMonitoredApiCall';
import { menusState } from 'root/states/MenusState';

export const PopulateMenuIdsByOperationClient = ({
  httpClient,
  experiments,
  reportError,
  sentry,
  fedopsLogger,
}: {
  httpClient: IHttpClient;
  experiments: Experiments;
  reportError?: ReportError;
  sentry?: ErrorMonitor;
  fedopsLogger?: FedopsLogger;
}) => {
  const getMenusByOperation = experiments.enabled(SPECS.getMenusByOperation);
  const ordersSettingsClient = new OrdersSettingsClient(
    httpClient,
    sentry,
    fedopsLogger,
    reportError
  );

  return {
    getAll: async (operationIdPromise?: Promise<string | undefined>) => {
      if (!getMenusByOperation) {
        return undefined;
      }
      try {
        const operationId = await operationIdPromise;

        const fetchMenuIdsByOperation = async () => {
          const response = await ordersSettingsClient.getMenuOrderingSettingsOperationId(
            operationId,
            () => {
              getMenusByOperation && menusState.setHasError(true);
            }
          );

          return response.menuOrderingSettings || [];
        };

        const menuOrderingSettings = operationId ? await fetchMenuIdsByOperation() : undefined;

        return menuOrderingSettings
          ?.map((settings) => settings.menuId)
          .filter((settings) => !!settings) as string[] | undefined;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('fetch menuOrdersSettings failed', e);
        menusState.setHasError(true);
      }
    },
  };
};
