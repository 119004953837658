import type model from './model';
import { MENU_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import type { PopulatedMenu } from '../../types/menusTypes';
import type { I$W, TFunction } from '@wix/yoshi-flow-editor';
import { state } from '../../states/RootState';
import { MENU_STATES } from '../../types/businessTypes';
import type { ControllerParams } from '../../types/widgets';
import { menusState } from 'root/states/MenusState';

type BindAll = ControllerParams<typeof model>['$bindAll'];
type Bind = ControllerParams<typeof model>['$bind'];

export class MenusController {
  sectionsIdsMap: Record<string, string> = {};
  shouldShowErrorContent: boolean = false;

  constructor(private $bindAll: BindAll, private $w: I$W, private t: TFunction) {
    state.pubsub.subscribe('onScrollToMenu', this.scrollToMenu);
  }

  async init() {
    this.$bindAll({
      [MENU_WIDGET_COMPONENT_IDS.menuMultiStateBox]: {
        currentState: () => (menusState.isEmpty ? MENU_STATES.menuEmptystate : MENU_STATES.menu),
      },
      [MENU_WIDGET_COMPONENT_IDS.menuEmptyStateTitle]: {
        text: () =>
          menusState.hasError
            ? this.t('menu_olo.errorState.title')
            : this.t('menu_olo.emptyState.title'),
      },
      [MENU_WIDGET_COMPONENT_IDS.menuEmptyStateSubtitle]: {
        text: () =>
          menusState.hasError
            ? this.t('menu_olo.errorState.subTitle')
            : this.t('menu_olo.emptyState.subTitle'),
      },
      [MENU_WIDGET_COMPONENT_IDS.repeaterMenus]: {
        data: () => menusState.sortedMenusDto,
        item: (itemData: PopulatedMenu & { _id: string }, bindItem: Bind) => {
          bindItem(MENU_WIDGET_COMPONENT_IDS.menuTitle, {
            text: () => menusState.getMenu(itemData.id)?.menuDto?.name || '',
            collapsed: () => !menusState.getMenu(itemData.id)?.menuDto?.name,
          });

          bindItem(MENU_WIDGET_COMPONENT_IDS.menuDescription, {
            text: () => menusState.getMenu(itemData.id)?.menuDto?.description || '',
            collapsed: () => !menusState.getMenu(itemData.id)?.menuDto?.description,
          });

          bindItem(MENU_WIDGET_COMPONENT_IDS.menuDivider, {
            collapsed: () => !menusState.getMenu(itemData.id)?.menuDto?.sections.length,
          });

          bindItem(MENU_WIDGET_COMPONENT_IDS.sectionContainer, {
            data: () => {
              const values = {
                sections: menusState.getMenu(itemData.id)?.menuDto?.sections,
                menuId: menusState.getMenu(itemData.id)?.menuDto?.id,
              };
              return values;
            },
          });
        },
      },
    });
  }

  scrollToMenu = ({ menuId }: { menuId: string }) => {
    this.$w(MENU_WIDGET_COMPONENT_IDS.repeaterMenus).forItems([menuId], ($item: I$W) => {
      $item(MENU_WIDGET_COMPONENT_IDS.menuTitle).scrollTo();
    });
  };
}
