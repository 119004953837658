import model from './model';
import { MenusController } from './menusController';
import type { TFunction } from '@wix/yoshi-flow-editor';

export default model.createController(({ $w, $bindAll, $widget, flowAPI }) => {
  const t = flowAPI.translations.t as TFunction;
  const menusController = new MenusController($bindAll, $w, t);

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      menusController.init();
    },
    exports: {},
  };
});
